import appHistory from 'app/appHistory';
import { ADD_DOCUMENT_PAGE } from 'paths';
import PropTypes from 'prop-types';

import { Typography } from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button/Button';
import { StyledFullWidthBlueDivider } from '../../atoms/Divider/Divider.styles';
import { StyledNoDocumentsContainer } from './NoDocuments.styles';

const NoDocuments = ({
  className,
  addDocumentPath,
  noTitle,
  canAddDocument,
}) => (
  <StyledNoDocumentsContainer
    className={`bigStack ${className}`}
    data-testid="no-documents-container"
  >
    {!noTitle && (
      <div>
        <Typography
          as="h3"
          hideParagraphSpacing
          size="24"
          weight="bold"
          css={{ margin: 0 }}
        >
          You haven&apos;t added any documents yet
        </Typography>
        <StyledFullWidthBlueDivider />
      </div>
    )}
    {canAddDocument && addDocumentPath && (
      <>
        <Typography as="p" hideParagraphSpacing size="16">
          To add a document, click the button below.
        </Typography>

        <Button
          palette="primary"
          onClick={() => appHistory.push(addDocumentPath)}
          testId="no-documents-button"
          disabled={!canAddDocument}
        >
          Add document
        </Button>
      </>
    )}
  </StyledNoDocumentsContainer>
);

NoDocuments.propTypes = {
  className: PropTypes.string,
  addDocumentPath: PropTypes.string,
  canAddDocument: PropTypes.bool,
  noTitle: PropTypes.bool,
};

NoDocuments.defaultProps = {
  className: '',
  canAddDocument: false,
  addDocumentPath: ADD_DOCUMENT_PAGE,
  noTitle: false,
};

export default NoDocuments;
