import { useNextCutoffDate } from 'utils/queries/cutoffReports';

import {
  Box,
  formatDateWithTimeAndTimezone,
  Notification,
} from '@omnipresentgroup/design-system';

export const ExpenseCutOffNotification = ({
  lspId,
  countryCode,
}: {
  lspId: string;
  countryCode: string;
}) => {
  const { data } = useNextCutoffDate({ lspId, countryCode });

  return (
    <Box data-testing="blackout">
      {data && (
        <Notification
          id="expense-cutoff-alert"
          intent="warning"
          title={`Expenses need to be submitted to and approved by your manager by ${formatDateWithTimeAndTimezone(
            data.cutoff_date,
          )} to be reimbursed in the upcoming payroll`}
          description="If your submitted expenses are approved after this cut off date, then you will be reimbursed in next month’s payroll"
        />
      )}
    </Box>
  );
};
