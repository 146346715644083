import {
  Card,
  Icon,
  Inline,
  List,
  Stack,
  Switcher,
  Typography,
  useViewport,
} from '@omnipresentgroup/design-system';

type Props = {
  children: React.ReactNode;
  resourceItems: {
    id: string;
    label: string;
    icon: string;
    href: string;
  }[];
};
export const DetailsPageLayout = ({ children, resourceItems }: Props) => {
  const { isPhone, isTablet } = useViewport();

  return (
    <Switcher isStacked={isPhone || isTablet}>
      <Stack css={{ flexGrow: 1 }}>{children}</Stack>

      <Stack minW={250}>
        <Typography as="span" size="16" weight="medium">
          Resources
        </Typography>
        <List
          gap="8"
          items={resourceItems}
          keyExtractor="id"
          render={(item) => (
            <Stack gap="0">
              <Card
                interactive
                p="16"
                onClick={() => {
                  window
                    .open(item.href, '_blank', 'noopener,noreferrer')
                    ?.focus();
                }}
              >
                <Inline align="center" justify="space-between">
                  <Inline gap="8" align="center">
                    <Icon icon={item.icon} size="16" title={item.label} />
                    <Typography as="span" size="14" weight="medium">
                      {item.label}
                    </Typography>
                  </Inline>
                  <Icon icon="ExternalLink" size="16" title={item.label} />
                </Inline>
              </Card>
            </Stack>
          )}
        />
      </Stack>
    </Switcher>
  );
};
