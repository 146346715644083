import { EmployeeForDetailView } from 'common/types';
import { DateTime } from 'luxon';

import { formatDateWithTimeAndTimezone } from '@omnipresentgroup/design-system';

export const removableProperties = [
  'jobTitle',
  'jobDescription',
  'annualBaseSalary',
  'hoursOfWork',
];

export const getPropertiesToRemove = (employee: EmployeeForDetailView) => {
  let workStartDate = employee.employmentDetails.find(
    (detailField) => detailField.name === 'startDate',
  );

  const hasContractQuestionnaireStartDate =
    Object.keys(employee.contractQuestionnaire?.body || {}).length &&
    employee.contractQuestionnaire?.body.startDate?.value;

  if (hasContractQuestionnaireStartDate) {
    workStartDate = {
      value: employee.contractQuestionnaire?.body.startDate.value,
      name: 'startDate',
      label: 'Start date',
      type: 'date',
    };
  }

  const hasAlreadyStarted =
    DateTime.fromISO(workStartDate?.value) <= DateTime.now();

  return employee.onboardingStatus &&
    ['Active', 'Inactive'].includes(employee.onboardingStatus) &&
    hasAlreadyStarted
    ? removableProperties
    : [];
};

export const removePropertiesFromSection =
  (propertiesToRemove: Array<string>) => (section?: Array<any>) =>
    (section || []).reduce((acc, curr) => {
      if (!propertiesToRemove.includes(curr.name)) {
        acc.push(curr);
      }
      return acc;
    }, []);

export const getSections = (
  employee: EmployeeForDetailView,
  params: {
    nextCutoffDate: string;
  },
): {
  name: string;
  title: string;
  details: any;
  key?: string;
  contextualInfo?: string;
  notification?: {
    title: string;
    intent: string;
    description?: string;
  };
}[] => {
  const propertiesToRemove = getPropertiesToRemove(employee);
  const removePropertiesTo = removePropertiesFromSection(propertiesToRemove);

  return [
    {
      key: 'personal-details',
      name: 'personal-details',
      title: 'Personal details',
      details: removePropertiesTo(employee?.personalDetails),
    },
    {
      key: 'bank-details',
      name: 'bank-details',
      title: 'Bank details',
      contextualInfo:
        'Provide the bank details where you would like to receive your salary. Bank accounts vary by country, please provide us with the relevant information from your account ' +
        'e.g. IBAN & SWIFT/BIC or Account Number & Routing Number',
      notification: {
        intent: 'warning',
        title: `Any changes you make to your bank details or home address after ${formatDateWithTimeAndTimezone(
          params.nextCutoffDate,
        )} will take effect in next month’s payroll`,
        description:
          'If you are making changes after this cut off date ensure your old bank account remains open for the deposit of funds',
      },
      details: removePropertiesTo(employee?.bankingDetails),
    },
    {
      key: 'social-insurance-number',
      name: 'social-insurance-number',
      title: '',
      details: removePropertiesTo(employee?.socialInsuranceNumber),
    },
    {
      key: 'role-details',
      name: 'role-details',
      title: 'Role details',
      details: removePropertiesTo(employee?.roleDetails),
    },
    {
      key: 'employment-details',
      name: 'employment-details',
      title: 'Employment details',
      details: removePropertiesTo(employee?.employmentDetails),
    },
    {
      name: 'local-onboarding-details',
      title: 'Local onboarding details',
      details: removePropertiesTo(employee?.localOnboardingDetails),
    },
    {
      name: 'payroll-details',
      title: 'Payroll details (internal)',
      notification: {
        intent: 'info',
        title: 'Currency amendments',
        description:
          'The recurring fees (management fee and deposit fee) will automatically follow any changes to the billing currency',
      },
      details: removePropertiesTo(employee?.payrollDetails),
    },
    {
      name: 'admin-details',
      title: 'Administrative information (internal)',
      details: removePropertiesTo(employee?.administrativeInformation),
    },
  ];
};
