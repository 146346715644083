import { StyledBodyCopy } from 'common/components/atoms/Text/Copy.styles';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import NoDocuments from '../../molecules/NoDocuments/NoDocuments';

export const StyledDocumentsTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledNoDocuments = styled(NoDocuments)`
  align-self: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    align-self: start;
  }
`;

export const StyledDocumentListPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledShowingText = styled(StyledBodyCopy)`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  align-self: flex-end;
  margin-right: ${({ theme: { space } }) => space[16]};
`;

export const StyledDocumentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocumentListTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;
