import { FIELD_TYPE } from 'common/types';
import { formatCurrency } from 'utils/currencies';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

import { expenseCategoriesOptions } from '../../pages/AddEmployeeExpensePage/addEmployeeExpenseFormConfig';

const getApprovedAndRejectedDates = (statusHistory) => {
  const approvalUpdate = statusHistory.find(
    (update) => update.status === 'APPROVED',
  );

  const rejectionUpdate = statusHistory.find(
    (update) => update.status === 'REJECTED',
  );

  return {
    approvedDate: approvalUpdate?.statusChangedAt,
    rejectDate: rejectionUpdate?.statusChangedAt,
  };
};

export const transformExpenseFromApi = ({
  status,
  title,
  description,
  amount,
  dateSpent,
  documentId,
  approverName,
  paid,
  rejectionReason,
  requestedChange,
  statusHistory,
  category,
}) => {
  const { approvedDate, rejectDate } =
    getApprovedAndRejectedDates(statusHistory);

  return {
    status,
    title,
    approverName,
    description,
    paid,
    category,
    requestedChange,
    rejectionReason,
    expenseDetails: [
      {
        name: 'title',
        label: 'Spent at',
        value: title,
        type: 'string',
        editable: true,
      },
      {
        name: 'description',
        label: 'Description',
        value: description,
        type: 'string',
        editable: true,
      },
      {
        name: 'category',
        label: 'Category',
        value: category,
        type: FIELD_TYPE.DROPDOWN,
        editable: true,
        options: expenseCategoriesOptions,
      },
      {
        name: 'amount',
        label: 'Amount spent',
        value: amount,
        type: 'employeeCurrency',
        editable: true,
        displayValue: formatCurrency(amount.amount, amount.currency),
      },
      {
        name: 'dateSpent',
        label: 'Date spent',
        displayValue: formatISODate(dateSpent, DateFormats.Date, true, '-'),
        value: formatISODate(dateSpent, DateFormats.FixedDate, true),
        type: FIELD_TYPE.DATE,
        editable: true,
      },
      {
        name: 'documentId',
        label: 'Proof of purchase',
        value: documentId,
        type: 'receipt',
        editable: true,
      },
      ...(['APPROVED', 'PAID'].includes(status)
        ? [
            {
              name: 'approvedDate',
              label: 'Approved Date',
              value: formatISODate(approvedDate, DateFormats.Date, true, '-'),
              type: 'date',
              editable: false,
            },
          ]
        : []),
      ...(status === 'REJECTED'
        ? [
            {
              name: 'rejectedDate',
              label: 'Rejected Date',
              value: formatISODate(rejectDate, DateFormats.Date, true, '-'),
              type: 'date',
              editable: false,
            },
          ]
        : []),
    ],
  };
};

export const transformExpenseToApi = (expenseToTransform) => ({
  ...expenseToTransform,
  amount: expenseToTransform.amount?.amount,
  currency: expenseToTransform.amount?.currency,
});
