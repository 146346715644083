import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'assets/icon/ArrowRight.svg';
import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import Loading from 'common/components/atoms/Loading/Loading';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import Card from 'common/components/molecules/Card/Card';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import {
  StyledProfilePictureSmall,
  StyledShowingText,
} from 'common/pages/EditEmployeePage/EditEmployeePage.styles';
import {
  StyledEmployeeCardTitle,
  StyledManagerEmployeesTableContainer,
} from 'omniplatform/manager/pages/ManagerEmployeePage/ManagerEmployeePage.styles';
import { getEmployeeProfilePage } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';
import { useLSPEmployeesQuery } from 'utils/queries';

import { Viewport } from '@omnipresentgroup/design-system';

import { LspEmployeeTableHeaders } from './LspEmployeesPageTableHeaders';

export const LspEmployeesTable = ({ lspId }: { lspId: string }) => {
  const history = useHistory();
  const { main: userRole } = useSelectUserRoles();

  const lspsEmployeesQuery = useLSPEmployeesQuery({ lspId });

  const headers = LspEmployeeTableHeaders(userRole);
  const headersForCard = headers.filter(
    (header: { headerTitle: string }) => header.headerTitle !== 'Employee',
  );
  const onRowClick = (employeeId: string) =>
    history.push(getEmployeeProfilePage(employeeId));

  if (lspsEmployeesQuery.isLoading) {
    return <Loading />;
  }

  if (lspsEmployeesQuery.isError && lspsEmployeesQuery.error instanceof Error) {
    return <ErrorBanner errorMessage={lspsEmployeesQuery.error.message} />;
  }

  if (lspsEmployeesQuery.isSuccess) {
    const employees = lspsEmployeesQuery.data;

    return (
      <div data-testid="lsp-employees-component">
        <StyledManagerEmployeesTableContainer className="smallStack">
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <StyledShowingText>
              Showing 1 - {employees.length} of {employees.length}
            </StyledShowingText>
            <TableWithTitle
              testId="lsp-employees"
              itemsToDisplay={employees}
              onClick={onRowClick}
              headers={headers}
            />
          </Viewport>
          <Viewport devices={['phone', 'tablet']}>
            <MobileCardListHeader total={employees.length} />
            {!!employees?.length &&
              employees.map((employee) => (
                <Card
                  key={employee.id}
                  fieldsToShow={headersForCard}
                  onClick={onRowClick}
                  item={employee}
                  icon={<ArrowRight />}
                  title={
                    <StyledEmployeeCardTitle>
                      <StyledProfilePictureSmall
                        src={avatarPlaceholderPicture}
                      />
                      <StyledBoldCopy>{employee.name}</StyledBoldCopy>
                    </StyledEmployeeCardTitle>
                  }
                />
              ))}
          </Viewport>
        </StyledManagerEmployeesTableContainer>
      </div>
    );
  }

  return <></>;
};
