import { cutoffReportsClient } from 'api';
import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

const mockCutoffReport = {
  reports: [
    {
      report_id: '36a8a8e7-6a89-4e3f-9e92-7427925de636',
      report_name: 'May 2024 YM1',
      lsp_id: 'WoY_jeI_0LSv',
      lsp_name: 'YM1',
      country_code: 'AD',
      country_name: 'Andorra',
      status: 'COMPLETED',
      generated_at: '2024-05-08T00:00:00',
    },
    {
      report_id: '70b15fb2-0248-4884-afa1-39c2fa979302',
      report_name: 'May 2024 Test Employer UAE',
      lsp_id: 'fjCXc0wrBH2H',
      lsp_name: 'Test Employer UAE',
      country_code: 'AE',
      country_name: 'United Arab Emirates',
      status: 'COMPLETED',
      generated_at: '2024-05-08T00:00:00',
    },
  ],
  total_count: 2,
};

const mockCutoffReportFilters = {
  country_codes: ['AD', 'AE', 'MW', 'ES', 'BZ', 'CY', 'GB', 'GH', 'KW'],
  lsps: [
    {
      lsp_name: 'Marina Manana',
      lsp_id: 'K1QLxfS4CbDv',
    },
    {
      lsp_name: 'Parakar France',
      lsp_id: 'OW52lVBSyQI1',
    },
    {
      lsp_name: 'Omnipresent South Africa (PTY) Ltd (Kreston via Neeyamo)',
      lsp_id: 'ljSkLp0HpfBz',
    },
  ],
};

export const cutoffReportHandlers = [
  rest.get(
    composeEndpoint('/reports/', cutoffReportsClient),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockCutoffReport)),
  ),
  rest.get(
    composeEndpoint('/report-filters', cutoffReportsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockCutoffReportFilters),
      ),
  ),
  rest.get(
    composeEndpoint('/next-cutoff-date*', cutoffReportsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          cutoff_date: '2025-02-26T10:34:08.744Z',
        }),
      ),
  ),
  rest.get(
    composeEndpoint('/reports/:reportId', cutoffReportsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          filename: 'some-file.zip',
          content: 'some-content',
        }),
      ),
  ),
];
