import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { createCompanyManagerRequest } from 'common/api/companies.api';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import { transformConfigFields } from 'common/components/molecules/Form/FormProvider';
import { HTTPStatusCodesEnum } from 'common/constants';
import { AddManagerFormPage } from 'common/pages/AddManagerFormPage/AddManagerFormPage';
import { addCompanyManagerFormConfig } from 'omniplatform/admin/pages/AddCompanyManagerPage/addCompanyManagerFormConfig';
import { useMutation } from 'react-query';

import { NewManager } from './type';

export const AddCompanyManagerPage = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const {
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    mutate: addManager,
  }: Record<string, any> = useMutation((newManager: NewManager) =>
    createCompanyManagerRequest(
      companyId,
      transformConfigFields(newManager.managerDetails[0]?.fields),
    ),
  );

  const errorMessage =
    isError &&
    error?.response?.status === HTTPStatusCodesEnum.CONFLICT &&
    DuplicateEmailErrorMessage;

  const nagivateToManagersList = useCallback(
    () =>
      window.location.assign(
        `${process.env.VITE_BO_APP}/companies/${companyId}/managers`,
      ),
    [companyId],
  );

  return (
    <AddManagerFormPage
      formConfig={addCompanyManagerFormConfig}
      isLoading={isLoading}
      addManager={addManager}
      isSuccess={isSuccess}
      companyId={companyId}
      successScreenPayload={data?.data}
      isError={isError}
      errorMessage={errorMessage || error?.message}
      onComplete={nagivateToManagersList}
      onExit={nagivateToManagersList}
    />
  );
};
