import {
  GetNextCutoffDateAPIResponse,
  ListCutoffReportsApiResponse,
  ListCutoffReportsFilter,
  ListCutoffReportsParams,
} from 'common/types';

import { cutoffReportsClient } from '../../api';

export const listCutoffReports = (params: ListCutoffReportsParams) => {
  return cutoffReportsClient.get<ListCutoffReportsApiResponse>('/reports/', {
    params: {
      page: params.pageIndex++,
      per_page: params.pageSize,
      from_date: params.from,
      to_date: params.to,
      lsp_id: params.lsp,
      country_code: params.country,
    },
  });
};

export const listCutoffReportFilters = () => {
  return cutoffReportsClient.get<ListCutoffReportsFilter>('/report-filters');
};

export const downloadCutoffReport = (
  id: string,
  setProgress: (progress: number) => void,
) => {
  return cutoffReportsClient.get<Blob>(`/reports/${id}`, {
    responseType: 'blob',
    onDownloadProgress: (progressEvent: ProgressEvent) => {
      if (progressEvent.total > 0) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        setProgress(progress);
      }
    },
  });
};

export const getNextCutoffDate = (params: {
  countryCode: string;
  lspId: string;
}) => {
  return cutoffReportsClient.get<GetNextCutoffDateAPIResponse>(
    '/next-cutoff-date',
    {
      params: {
        lsp_id: params.lspId,
        country_code: params.countryCode,
      },
    },
  );
};
