export const mockEmployee = {
  id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
  userId: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
  lspId: 'of1WbA9Hc2OP',
  externalId: 'SS1-22-6242793',
  country: {
    id: 'tgy2967b-f6fa-48a7-82f0-cf82ecd8b3u7',
    name: 'Germany',
    currency: 'EUR',
  },
  adminNotes: 'Some random notes',
  managerId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  profilePicture: 'https://randomuser.me/api/portraits/women/47.jpg',
  contactDetails: {
    personalEmail: 'simone@testemployee.com',
    phoneNumber: '089/12345678',
    workEmail: 'simone2@testemployee.com',
  },
  onboardingStatus: {
    status: 'In progress',
  },
  name: 'Simone Testerona',
  personalDetails: {
    salutation: 'Ms',
    firstName: 'Simone',
    middleNames: '',
    lastName: 'Testerona',
    gender: 'Female',
    pronouns: 'She/Her',
    dateOfBirth: '1989-12-03',
    socialInsuranceNumber: {
      localName: 'RNVR number',
      number: '123456789',
    },
    maritalStatus: 'single',
    noOfChildren: 0,
    privateAddress: {
      addressLine1: '123 Test apartment',
      addressLine2: 'Test Road',
      city: 'Berlin',
      postalCode: '12 890',
      country: 'Germany',
    },
    citizenship: 'German',
    identificationDocument: {
      type: 'passport',
      link: 'http://link-to-passport.com',
    },
    bankingDetails: 'IBAN: dfsvdf3434r3dfvd',
  },
  employeeType: 'EOR',
  employmentDetails: {
    rightToWorkCheckCompleted: true,
    locationOfWork: {
      type: 'Home office',
      address: {
        addressLine1: '123 Test apartment',
        addressLine2: 'Test Road',
        city: 'Toronto',
        postalCode: '11 890',
        country: 'Canada',
      },
    },
    jobTitle: 'CTO',
    hasSupplementaryBenefits: 'yes',
    startDate: '2020-07-27',
    jobDescription: 'CTO of 123 example co',
    probationaryPeriod: {
      length: 2,
      timeUnit: 'Months',
    },
    annualBaseSalary: {
      amount: 100000,
      currency: 'EUR',
    },
    additionalCompensation: '30% bonus yearly',
    hoursOfWork: 40,
    leave: {
      holidayAllowance: 20,
      holidayTakenTotal: 0,
      sickLeaveTaken: 0,
    },
  },
  contracts: [
    {
      createdAt: '2021-12-09T09:59:51.089Z',
      documentId: 'f606862a-1a48-4d24-8070-90f30263b123',
      status: 'approved',
      version: 2,
    },
  ],
  localOnboardingDetails: [
    {
      label: 'National insurance number',
      type: 'string',
      name: 'local-onboarding-details-national-insurance-number',
      value: '2',
      displayValue: '2',
      managerEditable: false,
      employeeEditable: true,
      required: true,
    },
    {
      label: 'Has eligibility to work in the country in which you live?',
      type: 'checkbox',
      name: 'local-onboarding-details-has-eligibility-to-work-in-living-country',
      value: [
        {
          label: 'Yes',
          value: true,
          selected: true,
        },
        {
          label: 'No',
          value: false,
          selected: false,
        },
      ],
      displayValue: 'Yes',
      managerEditable: false,
      employeeEditable: true,
      required: true,
    },
    {
      label: 'Type of work permit',
      type: 'string',
      name: 'local-onboarding-details-type-of-work-permit',
      value: 'Passport/citizenship',
      displayValue: 'Passport/citizenship',
      managerEditable: false,
      employeeEditable: true,
      required: true,
    },
  ],
  payrollDetails: {
    managementFee: {
      currency: 'EUR',
      amount: '50',
    },
    setupFee: {
      currency: 'EUR',
      amount: '20',
    },
    billingCurrency: 'EUR',
    depositType: 'Advanced Payment Deposit (APD)',
  },
};
