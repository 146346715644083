import { AxiosError } from 'axios';
import { getNextCutoffDate } from 'common/api/cutoffReports.api';
import { GetNextCutoffDateAPIResponse } from 'common/types/cutoffReports';
import { useQuery, UseQueryResult } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useNextCutoffDate = ({
  lspId,
  countryCode,
  useErrorBoundary = false,
}: {
  lspId: string;
  countryCode: string;
  useErrorBoundary?: boolean;
}): UseQueryResult<GetNextCutoffDateAPIResponse, AxiosError> => {
  return useQuery(
    ['next-cutoff-date', lspId, countryCode],
    () => getNextCutoffDate({ lspId, countryCode }).then(getDataFromResponse),
    {
      enabled: !!lspId && !!countryCode,
      useErrorBoundary,
    },
  );
};
