import PropTypes from 'prop-types';
import { useSortDataByCreatedAt } from 'utils/hooks/useSortDataByCreatedAt/useSortDataByCreatedAt';

import { Viewport } from '@omnipresentgroup/design-system';

import DateSort from '../../molecules/DateSort/DateSort';
import DocumentCard from '../../molecules/DocumentCard/DocumentCard';
import {
  StyledDocumentListTableContainer,
  StyledDocumentsHeader,
  StyledDocumentsTab,
  StyledNoDocuments,
  StyledShowingText,
} from './DocumentsTab.styles';

const DocumentsTab = ({
  documents,
  onDocumentDelete,
  canAddDocument,
  canDeleteDocument,
  canDownloadDocument,
  addDocumentPath,
}) => {
  const { orderedData: documentsToDisplay, setSortMethod } =
    useSortDataByCreatedAt(documents);

  if (!documents) {
    return null;
  }

  return (
    <StyledDocumentsTab data-testid="documents-tab">
      {documentsToDisplay && !documentsToDisplay.length && (
        <StyledNoDocuments
          addDocumentPath={addDocumentPath}
          canAddDocument={canAddDocument}
          noTitle
        />
      )}

      {documentsToDisplay && !!documentsToDisplay.length && (
        <StyledDocumentListTableContainer className="smallStack">
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <StyledDocumentsHeader>
              <StyledShowingText>
                Showing 1 - {documentsToDisplay.length} of{' '}
                {documentsToDisplay.length}
              </StyledShowingText>
              <DateSort
                onUpArrowClicked={() => setSortMethod('asc')}
                onDownArrowClicked={() => setSortMethod('desc')}
              />
            </StyledDocumentsHeader>
          </Viewport>
          {documentsToDisplay.map((document) => (
            <DocumentCard
              key={document.id}
              document={document}
              canDeleteDocument={canDeleteDocument(document.id)}
              canDownloadDocument={canDownloadDocument(document.id)}
              onDeleteDocument={onDocumentDelete}
            />
          ))}
        </StyledDocumentListTableContainer>
      )}
    </StyledDocumentsTab>
  );
};

DocumentsTab.propTypes = {
  documents: PropTypes.array,
  addDocumentPath: PropTypes.string,
  onDocumentDelete: PropTypes.func,
  canAddDocument: PropTypes.bool,
  canDeleteDocument: PropTypes.func,
  canDownloadDocument: PropTypes.func,
};

DocumentsTab.defaultProps = {
  documents: null,
  addDocumentPath: null,
  onDocumentDelete: null,
  canAddDocument: false,
  canDeleteDocument: () => false,
  canDownloadDocument: () => false,
};

export default DocumentsTab;
