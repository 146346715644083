import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import {
  getLoadingSelector,
  getUserRoleSelector,
  getUserSelector,
} from 'app/store/app.selectors';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import { getSections } from 'common/helpers/employeeDetailsSections';
import {
  clearSelectedEmployeeAction,
  getEmployeeAction,
  updateEmployeeAction,
} from 'common/store/actions/employee.actions';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import {
  transformDepartmentListOptions,
  transformLSPListOptions,
} from 'common/store/transformers/lsps.transformer';
import { Field } from 'common/types';
import { EMPLOYEE_PROFILE_PAGE, getEmployeeViewPath } from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryCodeByCountryName } from 'utils/countries';
import { showEmployeeReviewForm } from 'utils/employees';
import { useDepartmentQuery, useLspsQuery } from 'utils/queries';
import { useNextCutoffDate } from 'utils/queries/cutoffReports';
import { isEmployee } from 'utils/user';
import {
  employeeSchema,
  getLocalOnboardingSchema,
} from 'utils/validators/employee.schema';
import { validateInputs } from 'utils/validators/validator';

import { EditDetailsPage } from '../EditDetailsPage/EditDetailsPage';
import { EmployeeDetailsNotificationModal } from '../EditDetailsPage/EmployeeDetailsNotificationModal';
import ProfileWelcomePage from '../ProfileWelcomePage/ProfileWelcomePage';
import { StyledEditEmployeePage } from './EditEmployeePage.styles';
import { getEditableDetailsByUserType } from './getEditableDetailsByUser';
import SideBar from './SideBar';

export const EditEmployeePage = () => {
  const location = useLocation();

  const history = useHistory();

  const { employeeId } = useParams<{ employeeId: string }>();
  const dispatch = useDispatch();

  const employee = useSelector(getSelectedEmployeeSelector);
  const loading = useSelector(getLoadingSelector);
  const user = useSelector(getUserSelector);
  const userRole = useSelector(getUserRoleSelector);

  const lspsQuery = useLspsQuery();
  const departmentsQuery = useDepartmentQuery();

  const { data: nextCutoffDate } = useNextCutoffDate({
    lspId: employee.lspId,
    countryCode: getCountryCodeByCountryName(employee?.country),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    dispatch(clearSelectedEmployeeAction());
    dispatch(getEmployeeAction(employeeId));
  }, [dispatch, employeeId]);

  const scrollIntoHash = (location?.hash || '').substring(1);
  // FIXME: Checkbox field passes an index-based id so cannot use document.getElementById
  const fieldToScrollTo = document.querySelector(
    `input[name="${scrollIntoHash}"]`,
  );

  // FIXME: does not work all the time because of un-necessary re-renders of the page
  fieldToScrollTo?.scrollIntoView({ behavior: 'smooth' });

  if (
    lspsQuery.isSuccess &&
    lspsQuery.data &&
    lspsQuery.data.length > 0 &&
    employee &&
    employee.employmentDetails
  ) {
    const lspField = employee.employmentDetails.find(
      (detailField: Field) => detailField.name === 'lspId',
    );

    if (lspField) {
      lspField.options = transformLSPListOptions(lspsQuery.data);
    }
  }

  if (
    departmentsQuery.isSuccess &&
    employee &&
    employee.administrativeInformation &&
    departmentsQuery.data &&
    departmentsQuery.data.length > 0
  ) {
    const deptField: Field = employee.administrativeInformation.find(
      (detailField: Field) => detailField.name === 'department',
    );
    if (deptField) {
      const deptOptions = transformDepartmentListOptions(departmentsQuery.data);
      deptField.options = deptOptions;
    }
  }

  if (departmentsQuery.isError && departmentsQuery.error instanceof Error) {
    return <ErrorBanner errorMessage={departmentsQuery.error.message} />;
  }

  if (
    loading ||
    lspsQuery.isLoading ||
    departmentsQuery.isLoading ||
    !employee?.personalDetails ||
    !employee.employmentDetails ||
    !employee.administrativeInformation ||
    !employee.roleDetails ||
    !employee.localOnboardingDetails ||
    !user?.roles?.length
  ) {
    return <Loading />;
  }

  const validationSchema = getLocalOnboardingSchema(
    employee?.localOnboardingDetails,
  );

  const employeeDetailsSections = getSections(employee, {
    nextCutoffDate: nextCutoffDate?.cutoff_date || '',
  });

  const bankDetailsAndAddressFields = [
    'address',
    'accountNumber',
    'bicSwift',
    'branchName',
    'iban',
    'routingNumber',
  ];

  const onUpdateMade = (updatedValues: Record<string, any>) => {
    let isBankDetailsOrAddressFieldUpdated = false;

    bankDetailsAndAddressFields.some((field) => {
      if (updatedValues[field]) {
        setIsModalOpen(true);
        isBankDetailsOrAddressFieldUpdated = true;
      }
    });
    dispatch(
      updateEmployeeAction(updatedValues, isBankDetailsOrAddressFieldUpdated),
    );
  };
  // FIXME: why is this page even shared when based on user type we show a different form for employee.
  const showWelcomePage = isEmployee(user) && showEmployeeReviewForm(employee);

  return (
    <>
      <EmployeeDetailsNotificationModal
        isModalOpen={isModalOpen}
        closeFn={() => {
          setIsModalOpen(!isModalOpen);
          history.push(EMPLOYEE_PROFILE_PAGE);
        }}
      />
      <PageContent key="edit-employee-form">
        <StyledEditEmployeePage data-testid="edit-employee-page">
          {showWelcomePage ? (
            <ProfileWelcomePage
              submitCTA="Next"
              title="Complete basic profile"
              welcomeMessages={[
                `Please verify and complete the following information to create your employee profile`,
              ]}
              onSaveEdits={(values: any) => {
                const updatedValues = {
                  ...values,
                  needsRequiredFieldsFilled: false,
                };
                onUpdateMade(updatedValues);
              }}
              sections={getEditableDetailsByUserType(
                employeeDetailsSections,
                user,
              )}
              validator={(fields: any) =>
                validateInputs(fields, employeeSchema)
              }
            />
          ) : (
            <EditDetailsPage
              backLink={{
                url:
                  userRole === 'employee'
                    ? EMPLOYEE_PROFILE_PAGE
                    : getEmployeeViewPath(employee.id),
                label:
                  userRole === 'employee'
                    ? 'Back to profile page'
                    : "Back to the employee's details",
              }}
              title="Edit employee details"
              country={employee.country}
              onSaveEdits={onUpdateMade}
              sections={getEditableDetailsByUserType(
                employeeDetailsSections,
                user,
              )}
              sideBar={
                <FormSidebar
                  child={
                    <SideBar
                      jobTitle={employee.jobTitle}
                      name={employee.name}
                    />
                  }
                />
              }
              validator={(fields) => validateInputs(fields, validationSchema)}
            />
          )}
        </StyledEditEmployeePage>
      </PageContent>
    </>
  );
};
