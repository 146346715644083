import { useEffect, useState } from 'react';

import appHistory from 'app/appHistory';
import { ReactComponent as ArrowRight } from 'assets/icon/ArrowRight.svg';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import { TableFilterActionWrapper } from 'common/components/atoms/TableFilterAction/TableFilterAction.styled';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import Card from 'common/components/molecules/Card/Card';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import SearchInput from 'common/components/molecules/SearchInput/SearchInput';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { COMPANIES_LIST_PAGE } from 'paths';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors/useSelectUserRoles';

import { Typography, Viewport } from '@omnipresentgroup/design-system';

import { StyledCompanyListMobile } from './CompanyListPage.styles';
import { headers } from './companyTableHeaders';

type CompanyListTabProps = {
  companies: any[];
  onAddCompanyClicked: () => any;
};

export const CompanyListTab = ({
  companies,
  onAddCompanyClicked,
}: CompanyListTabProps) => {
  const { isCSAdmin, isOPAdmin } = useSelectUserRoles();
  const [filteredCompanies, updateFilteredCompanies] = useState(companies);
  const onCompanyClick = (companyId: string) =>
    appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}`);

  useEffect(() => {
    updateFilteredCompanies(companies);
  }, [companies]);

  const onFilteredData = (filteredData: any[]) =>
    updateFilteredCompanies(filteredData);

  const headersForCard = headers
    .map((header) => {
      return header;
    })
    .filter(
      (header) =>
        header.fieldOnObject !== 'companyName' &&
        header.fieldOnObject !== 'onboardingStage',
    );

  return (
    <StyledTableContainer>
      <TableFilterActionWrapper>
        <SearchInput
          searchField="companyName"
          dataToSearch={companies}
          placeHolderText="Search by company name"
          onFilteredData={onFilteredData}
          focus
        />
      </TableFilterActionWrapper>
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        <TableWithTitle
          headers={headers}
          itemsToDisplay={filteredCompanies?.length ? filteredCompanies : []}
          testId="client-list"
          showButton
          onClick={onCompanyClick}
          emptyState={{
            title: "It's a little empty here",
            description: 'No companies to display',
          }}
        />
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <StyledCompanyListMobile className="stack">
          <Typography size="24" weight="medium" as="h4">
            Clients
          </Typography>
          <MobileCardListHeader total={filteredCompanies?.length} />
          {!!filteredCompanies?.length &&
            filteredCompanies.map((company) => (
              <Card
                key={company.id}
                fieldsToShow={headersForCard}
                onClick={onCompanyClick}
                item={company}
                icon={<ArrowRight />}
                title={<StyledBoldCopy>{company.companyName}</StyledBoldCopy>}
              />
            ))}
          {(isCSAdmin || isOPAdmin) && (
            <FloatingActionButton type="add" onClick={onAddCompanyClicked} />
          )}
        </StyledCompanyListMobile>
      </Viewport>
    </StyledTableContainer>
  );
};
