import { Fragment } from 'react';

import DetailsCardValue from 'common/components/molecules/DetailsCardValue/DetailsCardValue';
import { UserRoleType } from 'common/types';
import { Field } from 'common/types/form';
import { getIsRedactedValueMissing } from 'utils/rbac.utils';

import { Stack, Typography } from '@omnipresentgroup/design-system';

import {
  StyledDetail,
  StyledDetailLabel,
  StyledDetailsCardContainer,
  StyledDetailsContainer,
  StyledHeader,
} from './DetailsCard.styles';

const shouldDisplayField = ({
  type,
  value,
  showFor,
  userRole,
  hideFromDetailsView,
}: Field & { userRole?: UserRoleType }) => {
  const shouldHideValue =
    hideFromDetailsView || (type === 'conditionalInput' && !value);
  const isAvailableForRole = (userRole && showFor?.includes(userRole)) ?? true;
  return (
    isAvailableForRole && !shouldHideValue && !getIsRedactedValueMissing(value)
  );
};

type Props = {
  oneInputPerRow?: boolean;
  title?: string;
  fieldsToDisplay: (Field | any)[]; // FIXME: lspDetailsSections calls DetailsCard with fields omitting `name` prop
  testId?: string;
  onUpdateMade?: (fields: Field) => void;
  userRole?: UserRoleType;
  sendPlatformInvitation?: () => void;
  platformInvitationProgress?: string;
  head?: JSX.Element;
  showContextualInfo?: boolean;
  className?: string;
  validator?: (data: any) => any;
};

const DetailsCard = ({
  title,
  testId,
  userRole,
  fieldsToDisplay,
  sendPlatformInvitation,
  platformInvitationProgress,
  head,
  showContextualInfo,
  className = '',
}: Props) => {
  const filteredFieldsToDisplay = fieldsToDisplay.filter((fieldValues) =>
    shouldDisplayField({ ...fieldValues, userRole }),
  );

  return (
    <StyledDetailsContainer className={`smallStack ${className}`}>
      {title && (
        <StyledHeader>
          <Stack align="center">
            <Typography as="label" size="16" weight="medium">
              {title}
            </Typography>
          </Stack>
        </StyledHeader>
      )}
      <StyledDetailsCardContainer data-testid={testId}>
        {head}
        <tbody>
          {filteredFieldsToDisplay.map(
            ({
              type,
              label,
              value,
              detailLabel,
              displayValue,
              contextualInfo,
            }: Field) => (
              <Fragment key={label + type}>
                <StyledDetail key={label}>
                  <StyledDetailLabel>{detailLabel || label}</StyledDetailLabel>
                  <DetailsCardValue
                    className="details-card-value"
                    type={type}
                    label={label}
                    value={value}
                    displayValue={displayValue}
                    sendPlatformInvitation={sendPlatformInvitation}
                    platformInvitationProgress={platformInvitationProgress}
                    testId={testId + ' ' + label}
                  />
                </StyledDetail>
                {Boolean(contextualInfo) && showContextualInfo && (
                  <StyledDetail
                    key={`${label}-info`}
                    className="contextual-info"
                  >
                    <StyledDetailLabel colSpan={2}>
                      {contextualInfo}
                    </StyledDetailLabel>
                  </StyledDetail>
                )}
              </Fragment>
            ),
          )}
        </tbody>
      </StyledDetailsCardContainer>
    </StyledDetailsContainer>
  );
};

export default DetailsCard;
