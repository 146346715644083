import { PageContent } from 'app/App.styles';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import FormHeader from 'common/components/molecules/FormHeader/FormHeader';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import StagedForm from 'common/components/organisms/StagedForm/StagedForm';
import {
  StyledFormPage,
  StyledFormPageContent,
} from 'common/components/organisms/StyledFormPage/StyledFormPage.styles';

import { Viewport } from '@omnipresentgroup/design-system';

export const AddManagerFormPage = ({
  isLoading,
  formConfig,
  addManager,
  isSuccess,
  companyId,
  successScreenPayload,
  isError,
  errorMessage,
  onComplete,
  onExit,
}: {
  isLoading: boolean;
  formConfig: Record<string, any>;
  addManager: (arg: Record<string, any>) => void;
  isSuccess: boolean;
  successScreenPayload?: Record<string, any>;
  companyId: string;
  isError?: boolean;
  errorMessage?: string;
  onComplete?: () => void;
  onExit?: () => void;
}) => {
  return (
    <PageContent>
      {isError && <ErrorBanner errorMessage={errorMessage} />}
      <StyledFormPage data-testid="add-company-manager-page">
        <StyledFormPageContent>
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <FormSidebar
              title={formConfig.formTitle}
              copy={formConfig.formCopy}
            />
          </Viewport>
          <Viewport devices={['phone', 'tablet']}>
            <FormHeader
              title="Add a manager"
              stages={formConfig.stages.length}
              currentStage={formConfig.stages[0].stage}
            />
          </Viewport>
          <StagedForm
            formConfig={formConfig}
            onFormSubmit={(managerDetails: any) =>
              addManager({ companyId, managerDetails })
            }
            loading={isLoading}
            successScreenPayload={successScreenPayload}
            submissionSuccess={isSuccess}
            onFormComplete={onComplete}
            onExitClick={onExit}
          />
        </StyledFormPageContent>
      </StyledFormPage>
    </PageContent>
  );
};
